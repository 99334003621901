<template>
  <router-link
    tag="div"
    class="flex justify-between items-center py-3 border-b border-gray-900 last:border-0"
    :to="`/costumes/edit/${item.id}`"
  >
    <img
      v-if="item.thumbnail"
      v-lazy="`/uploads/costumes/${item.thumbnail}`"
      class="w-12 h-12 rounded-xl shadow-xl object-cover flex-shrink-0"
    >
    <div
      v-else
      class="w-12 h-12 rounded-xl shadow-xl bg-gray-800 flex-shrink-0"
    />
    <div class="flex-grow px-4 overflow-hidden">
      <p class="text-main text-sm truncate">
        {{ item.name }}
      </p>
      <p class="text-xs text-sub">
        {{ item.costume_size ? item.costume_size.name : '不明' }}
      </p>
    </div>
    <button
      class="w-4 text-right flex-shrink-0 focus:outline-none"
      @click.stop="$emit('action', item)"
    >
      <font-awesome-icon
        class="text-sub text-opacity-75 text-lg"
        :icon="['fas', 'caret-square-down']"
      />
    </button>
  </router-link>
</template>

<script>
export default {
  name: 'CostumeItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
