<template>
  <div v-if="item">
    <img
      v-if="item.thumbnail"
      :src="`/uploads/costumes/${item.thumbnail}`"
      class="rounded-xl w-full h-48 object-cover mb-3 shadow-xl"
    >
    <div
      v-else
      class="rounded-xl w-full py-16 mb-3 text-center bg-gray-900 bg-opacity-50 text-disabled"
    >
      画像未登録
    </div>
    <h2 class="text-main font-bold text-xl mb-1">
      {{ item.name }}
    </h2>
    <ui-label
      v-if="item.costume_size"
      class="text-xxs inline-block px-3 py-1 mb-3"
    >
      {{ item.costume_size.name }}
    </ui-label>
    <div
      v-if="item.comment"
      class="text-xs text-sub mb-2"
    >
      {{ item.comment }}
    </div>
    <div class="mt-4 grid grid-cols-2 gap-4">
      <button
        class="py-3 focus:outline-none bg-level-2 rounded-xl shadow-xl"
        @click="$emit('click-transfer')"
      >
        <p class="text-pink-400">
          <icon-box-move class="h-9 mx-auto" />
        </p>
        <p class="text-pink-100 text-opacity-75 text-sm mt-2">
          物販登録
        </p>
      </button>
      <router-link
        tag="button"
        :to="`/costumes/edit/${item.id}`"
        class="py-3 focus:outline-none bg-level-2 rounded-xl shadow-xl"
      >
        <p class="text-yellow-400">
          <icon-clipboard-with-pen class="h-9 mx-auto" />
        </p>
        <p class="text-yellow-100 text-opacity-75 text-sm mt-2">
          編集
        </p>
      </router-link>
    </div>
    <ui-button
      class="w-full mt-6"
      color="error-2"
      @click="deleteConfirmDialog = true"
    >
      削除
    </ui-button>
    <ui-button
      class="w-full mt-3"
      color="secondary"
      @click="$emit('cancel')"
    >
      閉じる
    </ui-button>
    <ui-dialog
      v-model="deleteConfirmDialog"
      title="削除確認"
      type="error"
      :description="`${item.name}を削除してもよろしいですか？`"
    >
      <ui-button
        color="error"
        class="mb-2"
        @click="deleteCostume"
      >
        削除する
      </ui-button>
      <ui-button @click="deleteConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import CostumeService from '../../services/CostumeService';

export default {
  name: 'CostumeAction',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      deleteConfirmDialog: false,
    };
  },
  methods: {
    async deleteCostume() {
      this.deleteConfirmDialog = false;
      await CostumeService.remove(this.item.id);
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '衣装を削除しました！',
      });
      this.$emit('request-update');
      this.$emit('cancel');
    },
  },
};
</script>
