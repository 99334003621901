<template>
  <div>
    <ui-input v-model="searchBox" class="mb-4" placeholder="検索" />
    <ui-transition>
      <div v-if="!isLoading">
        <costume-item
          v-for="costume in costumes"
          :key="costume.id"
          :item="costume"
          @action="showAction"
        />
      </div>
    </ui-transition>
    <ui-action v-model="actionVisible">
      <costume-action
        :item="activeCostume"
        @click-transfer="openMerchandiseAddDialog"
        @request-update="silentFetch"
        @cancel="actionVisible = false"
      />
    </ui-action>
    <merchandise-edit-dialog
      v-model="merchandiseAddDialog"
      :schedule-selectable="true"
      :name="merchandiseAddName"
      :order-item-id="activeCostume ? activeCostume.order_item_id : null"
      @saved="onCostumeTransferred($event)"
    />
  </div>
</template>

<script>
import CostumeService from '../services/CostumeService'
import CostumeItem from '../components/costume/CostumeItem.vue'
import MerchandiseEditDialog from '../components/merchandise/MerchandiseEditDialog.vue'
import CostumeAction from '../components/costume/CostumeAction.vue'

export default {
  name: 'Costume',
  components: {
    CostumeItem,
    CostumeAction,
    MerchandiseEditDialog,
  },
  data: () => ({
    isLoading: true,
    costumes: [],
    actionVisible: false,
    activeCostume: null,
    merchandiseAddDialog: false,
    merchandiseAddName: '',
  }),
  computed: {
    searchBox: {
      get() {
        return this.$store.getters['app/costumeSearchBox']
      },
      set(value) {
        this.$store.commit('app/costumeSearchBox', value)
      },
    },
  },
  watch: {
    actionVisible(value) {
      if (!value) {
        this.activeCostume = null
      }
    },
    merchandiseAddDialog(value) {
      if (!value) {
        this.merchandiseAddName = ''
      }
    },
  },
  async created() {
    await this.fetch()
    this.$store.dispatch('layout/restorePosition')
  },
  methods: {
    async fetch() {
      this.isLoading = true
      this.costumes = await CostumeService.get()
      this.isLoading = false
    },
    async silentFetch() {
      this.costumes = await CostumeService.get()
    },
    showAction(costume) {
      this.actionVisible = true
      this.activeCostume = costume
    },
    openMerchandiseAddDialog() {
      this.merchandiseAddDialog = true
      this.merchandiseAddName = this.activeCostume.name
    },
    async onCostumeTransferred() {
      await CostumeService.remove(this.activeCostume.id)
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '物販に登録しました！',
      })
      this.actionVisible = false
      await this.silentFetch()
    },
  },
}
</script>
